import request from '@/utils/request'
///api/ProductProject/List /api/ProductProject/ListByUser

export function getProductProjectList(params) {
    return request({
        url: '/api/ProductProject/ListByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}