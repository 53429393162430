<template>
  <div class="container" ref="wrapper">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-search
      v-model="page.filterData.projectName"
      @search="onGoSearch"
      placeholder="请输入搜索关键词"
    />

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '' ? '#9d1d22' : ''"
          @click="changeSorterType('')"
          >未上</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '上市' ? '#9d1d22' : ''"
          @click="changeSorterType('上市')"
          >上市</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '生产' ? '#9d1d22' : ''"
          @click="changeSorterType('生产')"
          >生产</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '封样' ? '#9d1d22' : ''"
          @click="changeSorterType('封样')"
          >封样</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '设计' ? '#9d1d22' : ''"
          @click="changeSorterType('设计')"
          >设计</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.stages == '立意' ? '#9d1d22' : ''"
          @click="changeSorterType('立意')"
          >立意</van-tag
        >
      </van-col>
    </van-cell>

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.taskStatus == '' ? '#9d1d22' : ''"
          @click="changetaskStatus('')"
          >全部</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="
            page.filterData.taskStatus == '如期' ? '#9d1d22' : 'rgb(0 176 80)'
          "
          @click="changetaskStatus('如期')"
          >如期</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.taskStatus == '延期' ? '#9d1d22' : '#ffc000'"
          @click="changetaskStatus('延期')"
          >延期</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.taskStatus == '提前' ? '#9d1d22' : '#564156'"
          @click="changetaskStatus('提前')"
          >提前</van-tag
        >
      </van-col>
      <van-col span="6">
        <van-tag
          round
          size="large"
          :color="page.filterData.taskStatus == '暂停' ? '#9d1d22' : '#ff0000'"
          @click="changetaskStatus('暂停')"
          >暂停/终止</van-tag
        >
      </van-col>
    </van-cell>

    <van-cell
      title="计划上市时间开始"
      :value="datestart"
      @click="showstart = true"
      is-link
    />
    <van-popup v-model="showstart" position="top" :style="{ height: '30%' }">
      <van-datetime-picker
        @cancel="showstart = false"
        @confirm="onConfirmstart"
        v-model="currentDatestart"
        type="date"
        title="选择上市时间开始"
      />
    </van-popup>

    <van-cell
      title="计划上市时间结束"
      :value="date"
      @click="showend = true"
      is-link
    />
    <van-popup v-model="showend" position="top" :style="{ height: '30%' }">
      <van-datetime-picker
        @cancel="showend = false"
        @confirm="onConfirm"
        v-model="currentDate"
        type="date"
        title="选择上市时间结束"
      />
    </van-popup>

    <!-- <van-calendar v-model="show" type="range" @confirm="onConfirm" /> -->

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3>{{ item.projectName }}</h3>
                </div>

                <!-- <div style="float: left">
                    <h4>采购时间：{{ DateFromat(item.orderTime) }}</h4>
                  
                    
                  </div> -->

                <div>
                  <h4 :style="GetTaskStatusColor(item.taskStatus)">
                    当前任务：{{ item.tasksInProgress }}
                  </h4>
                  <h4>主销部门：{{ item.salaDepartment }}</h4>
                  <!-- <h4>项目阶段： {{ item.stages }} </h4> -->
                  <h4>
                    <div>计划上市：{{ DateFromat(item.plannedEndTime) }}</div>
                  </h4>
                  <h4 v-if="item.stages.indexOf('上市') >= 0">
                    <div>实际上市：{{ DateFromat(item.actualEndTime) }}</div>
                  </h4>
                  <!-- <h4>
                    品牌定位： {{ item.brandPosition }} 优先级：{{
                      item.priority
                    }}
                  </h4> -->

                  <h4></h4>
                </div>
              </div>
            </template>
            <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
            <van-cell>
              <van-col span="24"> 产品编号 ： {{ item.projectCode }} </van-col>
              <van-col span="24"> 项目阶段： {{ item.stages }} </van-col>
              <!-- <h4>项目阶段： {{ item.stages }} </h4> -->
              <van-col span="24">品牌定位：{{ item.brandPosition }}</van-col>
              <van-col span="24" style=""> 优先级：{{ item.priority }}</van-col>
              <van-col span="24">工艺级别：{{ item.processLevel }}</van-col>

              <van-col span="24">香型：{{ item.flavor }}</van-col>
              <van-col span="24">酒精度：{{ item.alcohol }}</van-col>
              <van-col span="24">规格：{{ item.packSize }}</van-col>

              <van-col span="24">计划工期：{{ item.plannedDuration }}</van-col>
              <van-col span="6" v-if="item.stages.indexOf('上市') < 0"
                ><div style="height: 26px">剩余工期：</div></van-col
              ><van-col span="18" v-if="item.stages.indexOf('上市') < 0"
                ><div style="height: 26px; margin-top: 8px">
                  <van-progress
                    :percentage="getpercentage(item)"
                    :pivot-text="item.surplusDuration"
                    pivot-color="rgb(0 176 80)"
                    track-color="rgb(0 176 80)"
                    color="#e5e5e5"
                  />
                </div>
              </van-col>

              <!-- <van-col span="24">计划上市：{{ DateFromat(item.plannedEndTime) }}</van-col> -->
              <van-col span="24">总负责人：{{ item.projectManager }}</van-col>
              <van-col span="24">当前任务：{{ item.tasksInProgress }}</van-col>
              <van-col span="24"
                >当前任务负责人：{{ item.taskManager }}</van-col
              >
              <van-col span="24"
                ><span :style="GetTaskStatusColor(item.taskStatus)">
                  当前任务状态：{{ item.taskStatus }}</span
                ></van-col
              >
              <van-col span="24"
                >上一任务：{{ item.preTasksInProgress }}</van-col
              >
              <van-col span="24"
                >上一任务负责人：{{ item.preTaskManager }}</van-col
              >
              <van-col span="24"
                ><span :style="GetTaskStatusColor(item.preTaskStatus)">
                  上一任务状态：{{ item.preTaskStatus }}</span
                ></van-col
              >
              <van-col span="24">风险提示：{{ item.riskTips }}</van-col>
              <van-col span="24"
                ><div
                  class="acontent"
                  :data-itemid="item.projectCode"
                  v-html="item.detailsHtml"
                ></div>
              </van-col>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getProductProjectList } from "@/api/productProject";

//const key = "zn-history";
export default {
  name: "productProject",
  data() {
    return {
      currentDate: new Date(),
      currentDatestart: new Date(),
      showend: false,
      showstart: false,
      date: "",
      datestart: "",
      scroll: 0,
      title: "项目周进度",
      activeNames: "",
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],

      page: {
        current: 1,
        fromType: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          projectName: "",
          stages: "",
          taskStatus: "",
        },
      },
    };
  },

  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  updated() {
    this.addpreview();
  },

  watch: {
    // $route(to, from) {
    // },
  },

  methods: {
    DateFromat(data) {
      if (!data) {
        return "";
      }

      return data.replace(" 00:00:00", "").replace("T00:00:00", "");
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
    },
    onGoSearch() {
      (this.date = ""),
        (this.datestart = ""),
        (this.page.filterData.startDate = null);
      this.page.filterData.endDate = null;
      this.onSearch();
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getProductProjectList({ ...this.page }); //({ ...this.page })
      console.log(aresult);
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.filterData.stages != typeid) {
        this.date = "";
        this.datestart = "";
        this.page.filterData.startDate = null;
        this.page.filterData.endDate = null;
        this.page.filterData.stages = typeid;
        this.onSearch();
      }
    },

    changetaskStatus(typeid) {
      if (this.page.filterData.taskStatus != typeid) {
        this.date = "";
        this.datestart = "";
        this.page.filterData.startDate = null;
        this.page.filterData.endDate = null;
        this.page.filterData.taskStatus = typeid;
        this.onSearch();
      }
    },

    GetTaskStatusColor(taskStatus) {
      if(!taskStatus){
        return {};
      }

      if (taskStatus.indexOf("提前") >= 0) {
        return { background: "#564156", color: "#fff" };
      }
      if (taskStatus.indexOf("如期") >= 0) {
        return { background: "rgb(0 176 80)" };
      }
      if (taskStatus.indexOf("延期") >= 0) {
        return { background: "#ffc000" };
      }
      if (taskStatus.indexOf("停") >= 0) {
        return { background: "#ff0000" };
      }
      return {};
    },

    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");

      listdiv.forEach((acontentdiv, index) => {
        var imgs = acontentdiv.getElementsByTagName("img");
        var itemid = listdiv[index].dataset.itemid;
        imgs.forEach((element) => {
          element.setAttribute("preview", itemid);
        });
      });
      this.$previewRefresh();
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm() {
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      this.showend = false;
      (this.date = this.currentDate.Format("yyyy-MM-dd")),
        (this.page.filterData.endDate = this.currentDate);
      this.onSearch();
    },

    onConfirmstart() {
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      this.showstart = false;
      (this.datestart = this.currentDatestart.Format("yyyy-MM-dd")),
        (this.page.filterData.startDate = this.currentDatestart);
      this.onSearch();
    },
    getpercentage(item) {
      if (item.surplusDuration && item.plannedDuration) {
        var surplusDuration = item.surplusDuration
          .replace("天", "")
          .replace(" ", "");
        var plannedDuration = item.plannedDuration
          .replace("天", "")
          .replace(" ", "");

        if (parseInt(surplusDuration) > 0) {
          return (
            100 - (parseInt(surplusDuration) / parseInt(plannedDuration)) * 100
          );
        } else {
          return 100;
        }
      } else {
        return 50;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>
